import React from "react"
import landingStyles from "../styles/landing.module.scss"
import World from "./World"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

const Landing = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "milos.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const openVaultIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={landingStyles.icon}
    >
      <path fill="none" d="M0 0H24V24H0z" />
      <path d="M2 21v-2h2V4.835c0-.484.346-.898.821-.984l9.472-1.722c.326-.06.638.157.697.483.007.035.01.07.01.107v1.28L19 4c.552 0 1 .448 1 1v14h2v2h-4V6h-3v15H2zM13 4.396L6 5.67V19h7V4.396zM12 11v2h-2v-2h2z" />
    </svg>
  )

  return (
    <div className={landingStyles.landingContainer}>
      <World />
      <div className={landingStyles.column}>
        <motion.div
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
              delay: 0.1,
            },
          }}
          initial={{ opacity: 0 }}
          className={landingStyles.textContainer}
        >
          <h1 className={landingStyles.title}>Hi, I'm Milos</h1>
          <p>
            I am a Data Analyst at Booking.com in Amsterdam.
          </p>
          <p>
            My research explores how countries intervene in civil wars abroad. 
            I use R to create maps that make sense of big data. According to NodeXL,
            I am a Top 10 dataviz and R contributor on Twitter
          </p>
          <Link className={landingStyles.button} to="/visualizations">
            Open my Map Vault {openVaultIcon}
          </Link>
        </motion.div>
      </div>
      <motion.div
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
            delay: 0.3,
          },
        }}
        initial={{ opacity: 0 }}
        className={landingStyles.imageContainer}
      >
        <Img
          className={landingStyles.landingImage}
          fluid={data.file.childImageSharp.fluid}
          imgStyle={{ objectFit: "contain" }}
        />
      </motion.div>
    </div>
  )
}

export default Landing
