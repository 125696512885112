import React from "react"
import Layout from "../components/layout"
import Landing from "../components/landing"
import SEO from "../components/seo"

export default function Index(props) {
  const { location } = props

  return (
    <Layout hideHeader={true}>
      <SEO title="Home" pagePath={location.pathname} />
      <Landing />
    </Layout>
  )
}
